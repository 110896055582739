import React from "react"
import '../style/components/form.css'
import PulseLoader from "react-spinners/PulseLoader";

export const setInputValues = (fields, setValues) => {
    setValues(fields.reduce((acc, cur) => ({ ...acc, [cur]: { value: '', isActivated: false } }), {}))
}
export const handleChange = (event, values, setValues) => {
    setValues({ ...values, [event.target.name]: { ...values[event.target.name], value: event.target.value } });
}
export const handleBlur = (event, values, setValues) => {
    setValues({ ...values, [event.target.name]: { ...values[event.target.name], isActivated: true } });
}
export const Form = ({ children,title }) => {
    return (
        <form id='form'>
            <h1>{title}</h1>
            {children}
        </form>
    )
}

export const FormInput = ({ type, values, onChange, placeholder, onBlur, disabled, name ,required,maxLength}) => {
    if (!values) { return <div className={"formInput"}></div> }
    let isCorrect = values[name].value.length > 0
    let isActivated = values[name].isActivated
    if(required === undefined){required=false}
    if(!required){isActivated=false}
    if(!maxLength){maxLength=undefined}

    return (
        <div className={"formInput"}>
            <input
                type={type}
                value={values[name].value}
                onChange={onChange}
                onBlur={onBlur}
                name={name}
                placeholder={placeholder}
                disabled={disabled}
                required={required}
                className={(!isCorrect && isActivated) ? 'isIncorrect formInput' : 'isCorrect formInput'}
                maxLength={maxLength}
            />
            {(!isCorrect && isActivated && required) &&
                <p>{placeholder} is required.</p>
            }
        </div>

    )
}

export const FormError = ({ error }) => (
    <p className="formError">{error}</p>
)
export const FormButton = ({ text, onClick, disabled }) => (
    <button className="formButton" onClick={onClick} disabled={disabled}>
        {(text==='loader')?<PulseLoader color="#fff" size={10} margin={5} speedMultiplier={0.9}/>:text}
    </button>
)
export const FormText = ({html}) =>(
    <p className={'formText'}>{html}</p>
)