

import { useEffect, useState } from "react"
import Layout from "../../components/Layout"
import axios from "axios"
import { getCookie } from "../../helpers/helpers"



const Admin = () => {
    const [data, setData] = useState([])
    const [error, setError] = useState([])
    const [prematch_arbitrage_views, setPrematch_arbitrage_views] = useState([])
    const [dockerstats, setDockerstats] = useState([])
    const [serverstats, setServerstats] = useState([])
    const [environments, setEnvironments] = useState({})
    const [getGamesStats, setGetGamesStats] = useState([])
    const [getOffersStats, setGetOffersStats] = useState([])
    const [sportradaridStats, setSportradaridStats] = useState([])
    const [sportsStats, setSportsStats] = useState([])
    const [lastGetOffersStart, setLastGetOffersStart] = useState()
    const [siteViewsCount, setSiteViewsCount] = useState()
    const [usersStats, setUsersStats] = useState()
    const [invoices, setInvoices] = useState()
    const [partners, setPartners] = useState()
    const [bettracekerStats, setBettracekerStats] = useState()
    const [bettracekerLast, setBettracekerLast] = useState()
    const [arbitragesStats, setArbitragesStats] = useState({})
    const [password, setPassword] = useState('')
    const [dataFetched, setDataFetched] = useState(false)
    const token = getCookie("token")

    const p = '4539'

    const fetchNewData = async () => {
        axios.post(`${process.env.REACT_APP_API}/admin/get`, { token, options: {} },{timeout: 200000})
            .then(response => {
                setDataFetched(true)
                setPrematch_arbitrage_views(response.data.prematch_arbitrage_views)
                setDockerstats(response.data.dockerstats)
                setServerstats(response.data.server_stats)
                setEnvironments(response.data.environments)
                setGetGamesStats(response.data.getGamesStats)
                setGetOffersStats(response.data.getOffersStats)
                setSportradaridStats(response.data.sportradaridStats)
                setSportsStats(response.data.sportsStats)
                setLastGetOffersStart(response.data.lastGetOffersStart)
                setSiteViewsCount(response.data.siteViewsCount)
                setUsersStats(response.data.usersStats)
                setInvoices(response.data.invoices)
                setPartners(response.data.partners)
                setBettracekerStats(response.data.bettracekerStats)
                setBettracekerLast(response.data.bettracekerLast)
                setArbitragesStats(response.data.arbitragesStats)
            })
            .catch(error => {
                // setError(error.response.data.error)
            })
    }

    useEffect(() => {
        fetchNewData()
        const interval = setInterval(fetchNewData, 30000)
        return () => clearInterval(interval)
    }, [])


    const getGamesSites = [...new Set(getGamesStats.map(e => e.site))].filter(e => e)
    const getGamesSports = [...new Set(getGamesStats.map(e => e.sport))].filter(e => e)
    const getOffersSites = [...new Set(getOffersStats.map(e => e.site))].filter(e => e)
    const getOffersSports = [...new Set(getOffersStats.map(e => e.sport))].filter(e => e)
    const sportsStatsSites = [...new Set(sportsStats.map(e => e.site))].filter(e => e)
    const sportsStatsSports = [...new Set(sportsStats.map(e => e.sport))].filter(e => e)

    if (password !== p) {
        return (
            <Layout>
                <div className="container-fluid color-light d-flex justify-content-center align-items-center flex-column" style={{ 'width': '100%', "minHeight": "calc(80vh - 56px)" }}>
                    <div className="no-select d-flex justify-content-center align-items-center fs-3 text-light" style={{ "height": '45px' }}>
                        <div style={{ "width": '45px' }} className="d-block"></div>
                        <div className="mx-2" style={{ 'fontSize': '4rem' }}>
                            {"-".repeat(Math.abs(password.length))}

                        </div>
                        <div
                            className={` font1 rounded rounded-5 px-2  bg-secondary ${password.length === 0 && 'd-none'}`}
                            style={{ 'fontSize': '0.8rem', '--bs-bg-opacity': '.2', 'cursor': 'pointer', 'width': '45px' }}
                            onClick={() => setPassword('')}
                        >RESET</div>
                    </div>
                    <div className="no-select d-flex flex-column justify-content-center align-items-center">
                        {[[1, 2, 3], [4, 5, 6], [7, 8, 9]].map(e => {
                            return (
                                <div className="d-flex my-2">
                                    {e.map(i => {
                                        return (
                                            <div
                                                className="btn col-auto mx-2 p-0 bg-secondary rounded  text-light d-flex justify-content-center align-items-center fs-1 font2"
                                                style={{ "height": '80px', 'width': '80px', '--bs-bg-opacity': '.2', 'cursor': 'pointer', '--bs-border-radius': '100px' }}
                                                onClick={() => setPassword(`${password}${i}`)}
                                            >{i}</div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Layout>
        )
    }
    if (dockerstats.length === 0) { return <Layout><div>WAITING</div></Layout> }

    return (
        <Layout>
            <div className="container-fluid">
                <div className="row">
                    <div className="font1 text-light">Prematch Arbitrage Views:</div>
                    {prematch_arbitrage_views.map(e => {
                        return (
                            <div className="ms-4 font1 text-secondary">
                                {e.email}
                            </div>
                        )
                    })}
                </div>
                <div className="font1 text-light">Server Stats:</div>
                <div className="row text-secondary rounded rounded-3 mb-3 mx-0" style={{ 'background': '#222222' }}>
                    <div className="col-12">
                        CPU: {(((serverstats.cpu.average.avgTotal - serverstats.cpu.average.avgIdle) / serverstats.cpu.average.avgTotal) * 100).toFixed(2)}% [{serverstats.cpu.count}]
                    </div>
                    <div className="col-12">
                        MEMORY: {serverstats.memory.info.usedMemPercentage.toFixed(2)}% [{(serverstats.memory.info.usedMemMb / 1024).toFixed(2)}GB / {(serverstats.memory.info.totalMemMb / 1024).toFixed(2)}GB]
                    </div>
                    <div className="col-12">
                        DRIVE: {serverstats.drive.info.usedPercentage}% [{(serverstats.drive.info.usedGb)}GB / {(serverstats.drive.info.totalGb)}GB]
                    </div>
                </div>
                <div className="font1 text-light">Docker Stats:</div>
                {dockerstats.info.map(info => {
                    const stats = dockerstats.stats.find(e => e.id == info.id)

                    const cpuInfo = dockerstats.stats.reduce((acc, container) => {
                        return acc + container.cpuStats.online_cpus;
                    }, 0)
                    return (
                        <div className="row text-secondary rounded rounded-3 mb-3 mx-0" style={{ 'background': '#222222' }}>
                            <div className="col-12">
                                <div className="d-flex">
                                    <div className="me-2 text-light">
                                        {info.image.replace('bettingtools_', '')}
                                    </div>
                                    {/* <div className="me-2">
                                        {info.id.slice(0, 12)}
                                    </div> */}
                                    <div className="">
                                        {info.state}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                restarts: {stats.restartCount}
                            </div>
                            <div className="col-12">
                                CPU: {stats.cpuPercent.toFixed(2)}% [{stats.cpuStats.online_cpus}]
                            </div>
                            <div className="col-12">
                                memory: {stats.memPercent.toFixed(2)}% [{(stats.memUsage / (1024 ** 3)).toFixed(2)}GB / {(stats.memLimit / (1024 ** 3)).toFixed(2)}GB]
                            </div>
                            <div className="col-12">
                                NET I/O: {(stats.netIO.rx / (1024 ** 2)).toFixed(2)}MB / {(stats.netIO.wx / (1024 ** 2)).toFixed(2)}MB
                            </div>
                            <div className="col-12">
                                BLOCK I/O: {(stats.blockIO.r / (1024 ** 2)).toFixed(2)}MB / {(stats.blockIO.w / (1024 ** 2)).toFixed(2)}MB
                            </div>
                            <div className="col-12">
                                Pids: {stats.pids}
                            </div>
                        </div>
                    )
                })}
                <div className="font1 text-light">GetGames Stats:</div>
                <div className="row text-secondary rounded rounded-3 mb-3 mx-0" style={{ 'background': '#222222' }}>
                    <div className="col-12">
                        GetGames every {environments.getGamesHours} Hours
                    </div>
                    <div className="col-12">
                        Last GetGames {((new Date().getTime() - new Date(getGamesStats.find(e => e.code === "start").createdAt).getTime()) / (1000 * 60)).toFixed(0)} Minutes ago
                    </div>
                    <div className="col-12">
                        Lenght: {((new Date(getGamesStats.find(e => e.code === "end").createdAt).getTime() - new Date(getGamesStats.find(e => e.code === "start").createdAt).getTime()) / (1000 * 60)).toFixed(1)} Minutes
                    </div>
                    <div className="col-12 p-2" style={{ 'fontSize': '0.75rem' }}>
                        <div className="row">
                            <div className="col text-center p-0"></div>
                            {getGamesSites.map(site => {
                                return (
                                    <div className="col text-center p-0">
                                        {site}
                                    </div>
                                )
                            })}
                        </div>
                        {getGamesSports.map(sport => {
                            return (
                                <div className="row">
                                    <div className="col text-center p-0">
                                        {sport}
                                    </div>
                                    {getGamesSites.map(site => {
                                        console.log(sport, site)
                                        const value = getGamesStats.find(e => e.site === site && e.sport === sport).value
                                        return (
                                            <div className="col text-center p-0">
                                                {value}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="font1 text-light">GetOffers Stats:</div>
                <div className="row text-secondary rounded rounded-3 mb-3 mx-0" style={{ 'background': '#222222' }}>

                    <div className="col-12">
                        Last Start {((new Date().getTime() - new Date(lastGetOffersStart[0].createdAt).getTime()) / (1000 * 60)).toFixed(0)} Minutes Ago [{environments.getOffersFrom},{environments.getOffersUntil}]
                    </div>
                    <div className="col-12">
                        Last Finished GetOffers {((new Date().getTime() - new Date(getOffersStats.find(e => e.code === "start").createdAt).getTime()) / (1000 * 60)).toFixed(0)} Minutes ago
                    </div>
                    <div className="col-12">
                        Lenght: {((new Date(getOffersStats.find(e => e.code === "end").createdAt).getTime() - new Date(getOffersStats.find(e => e.code === "start").createdAt).getTime()) / (1000 * 60)).toFixed(1)} Minutes
                    </div>
                    <div className="col-12">
                        Total Games: {getOffersStats.filter(e => e.code === 'count-games').map(e => e.value).reduce((t, c) => t + c, 0)}                    </div>
                    <div className="col-12">
                        Total Offers: {getOffersStats.filter(e => e.code === 'count-offers').map(e => e.value).reduce((t, c) => t + c, 0)}
                    </div>
                    <div className="col-12 p-2" style={{ 'fontSize': '0.75rem' }}>
                        <div className="row">
                            <div className="col text-center p-0"></div>
                            {getOffersSites.map(site => {
                                return (
                                    <div className="col text-center p-0">
                                        {site}
                                    </div>
                                )
                            })}
                        </div>
                        {getOffersSports.map(sport => {
                            return (
                                <div className="row mt-3">
                                    <div className="col text-center p-0 d-flex align-items-center justify-content-center">
                                        {sport}
                                    </div>
                                    {getOffersSites.map(site => {
                                        console.log(sport, site)
                                        const gamesCount = getOffersStats.find(e => e.site === site && e.sport === sport && e.code === 'count-games').value
                                        const offersCount = getOffersStats.find(e => e.site === site && e.sport === sport && e.code === 'count-offers').value
                                        return (
                                            <div className="col text-center p-0">
                                                <div className="d-flex flex-column">
                                                    <div className="lh-1">
                                                        {gamesCount}
                                                    </div>
                                                    <div className="lh-1">
                                                        [{offersCount}]
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="font1 text-light">Sports Stats:</div>
                <div className="row text-secondary rounded rounded-3 mb-3 mx-0" style={{ 'background': '#222222' }}>
                    <div className="col-12">
                        Sports  {[...new Set(sportsStats.map(e => e.sport))].length}
                    </div>
                    <div className="col-12 p-2" style={{ 'fontSize': '0.75rem' }}>
                        <div className="row">
                            <div className="col text-center p-0"></div>
                            {sportsStatsSites.map(site => {
                                return (
                                    <div className="col text-center p-0 ">
                                        <div className="text-truncate">
                                            {site}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="row mt-3">
                            <div className="col text-center p-0 d-flex align-items-center justify-content-center">
                                all
                            </div>
                            {sportsStatsSites.map(site => {
                                const stats = sportradaridStats.find(e => e.site === site)
                                if (!stats) { return <div className="col p-0"></div> }

                                return (
                                    <div className="col text-center p-0">
                                        <div className="d-flex flex-column">
                                            <div className="lh-1">
                                                {stats.total_count}
                                            </div>
                                            <div className="lh-1">
                                                [{stats.count_not_null},{stats.count_null}]
                                            </div>
                                            <div className="lh-1">
                                                {(stats.count_not_null / stats.total_count * 100).toFixed(1)}%
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        {sportsStatsSports.map(sport => {
                            return (
                                <div className="row mt-3">
                                    <div className="col text-center p-0 d-flex align-items-center justify-content-center">
                                        {sport}
                                    </div>
                                    {sportsStatsSites.map(site => {
                                        console.log(sport, site)
                                        const stats = sportsStats.find(e => e.site === site && e.sport === sport)
                                        if (!stats) { return <div className="col p-0"></div> }

                                        return (
                                            <div className="col text-center p-0">
                                                <div className="d-flex flex-column">
                                                    <div className="lh-1">
                                                        {stats.total_count}
                                                    </div>
                                                    <div className="lh-1">
                                                        [{stats.count_not_null},{stats.count_null}]
                                                    </div>
                                                    <div className="lh-1">
                                                        {(stats.count_not_null / stats.total_count * 100).toFixed(1)}%
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="font1 text-light">Arbitrages Stats:</div>
                <div className="row text-secondary rounded rounded-3 mb-3 mx-0" style={{ 'background': '#222222' }}>
                    <div className="col-12 text-center">
                        Today
                    </div>
                    <div className="col-12 p-2" style={{ 'fontSize': '0.75rem' }}>
                        <div className="row">
                            <div className="col text-center p-0">ROI</div>
                            {['0-1', '1-3', '3-6', '6-10', '10-20', '20+'].map(e => {
                                return (
                                    <div className="col text-center p-0 ">
                                        <div className="text-truncate">
                                            [{e}%]
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="row">
                            <div className="col text-center p-0 d-flex align-items-center justify-content-center">

                            </div>
                            {['0-1', '1-3', '3-6', '6-10', '10-20', '20+'].map(e => {
                                const value = arbitragesStats[`today_${e.replace("-", '_')}`]
                                return (
                                    <div className="col text-center p-0 ">
                                        <div className="text-truncate">
                                            {value}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="row mt-3">
                            <div className="col text-center p-0">Count 3%+</div>
                            {['0-4', '4-8', '8-12', '12-16', '16-20', '20-24'].map(e => {
                                return (
                                    <div className="col text-center p-0 ">
                                        <div className="text-truncate">
                                            [{e}h]
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="row">
                            <div className="col text-center p-0 d-flex align-items-center justify-content-center">

                            </div>
                            {['0-4', '4-8', '8-12', '12-16', '16-20', '20-24'].map(e => {
                                const value = arbitragesStats[`today_${e.replace("-", '_')}h`]
                                return (
                                    <div className="col text-center p-0 ">
                                        <div className="text-truncate">
                                            {value}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-12 text-center">
                        Yesterday
                    </div>
                    <div className="col-12 p-2" style={{ 'fontSize': '0.75rem' }}>
                        <div className="row">
                            <div className="col text-center p-0">ROI</div>
                            {['0-1', '1-3', '3-6', '6-10', '10-20', '20+'].map(e => {
                                return (
                                    <div className="col text-center p-0 ">
                                        <div className="text-truncate">
                                            [{e}%]
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="row">
                            <div className="col text-center p-0 d-flex align-items-center justify-content-center">

                            </div>
                            {['0-1', '1-3', '3-6', '6-10', '10-20', '20+'].map(e => {
                                const value = arbitragesStats[`yesterday_${e.replace("-", '_')}`]
                                return (
                                    <div className="col text-center p-0 ">
                                        <div className="text-truncate">
                                            {value}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="row mt-3">
                            <div className="col text-center p-0">Count 3%+</div>
                            {['0-4', '4-8', '8-12', '12-16', '16-20', '20-24'].map(e => {
                                return (
                                    <div className="col text-center p-0 ">
                                        <div className="text-truncate">
                                            [{e}h]
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="row">
                            <div className="col text-center p-0 d-flex align-items-center justify-content-center">

                            </div>
                            {['0-4', '4-8', '8-12', '12-16', '16-20', '20-24'].map(e => {
                                const value = arbitragesStats[`yesterday_${e.replace("-", '_')}h`]
                                return (
                                    <div className="col text-center p-0 ">
                                        <div className="text-truncate">
                                            {value}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="font1 text-light">Site Visits:</div>
                <div className="row text-secondary rounded rounded-3 mb-3 mx-0" style={{ 'background': '#222222' }}>
                    {siteViewsCount.map(e => {
                        return (
                            <div className="col-12">
                                {e.email} {e.count} [{(new Date(e.last)).getHours()}:{((new Date(e.last)).getMinutes() < 10 ? '0' : '') + (new Date(e.last)).getMinutes()}]
                            </div>

                        )
                    })}
                </div>
                <div className="font1 text-light">BetTracker Stats:</div>
                <div className="row text-secondary rounded rounded-3 mb-3 mx-0" style={{ 'background': '#222222' }}>
                    <div className="col-12">
                        Today: {bettracekerStats.find(e => e.period === 'Today')?.count}
                    </div>
                    <div className="col-12">
                        Yesterday: {bettracekerStats.find(e => e.period === 'Yesterday')?.count}
                    </div>
                    <div className="col-12">
                        Last 24 Hours: {bettracekerStats.find(e => e.period === 'Last 24 hours')?.count}
                    </div>
                    <div className="col-12">
                        Last 7 Days: {bettracekerStats.find(e => e.period === 'Last 7 days')?.count}
                    </div>
                    <div className="col-12">
                        Last 30 Days: {bettracekerStats.find(e => e.period === 'Last 30 days')?.count}
                    </div>
                    <div className="col-12">
                        All Time: {bettracekerStats.find(e => e.period === 'all')?.count}
                    </div>
                </div>
                <div className="font1 text-light">BetTracker Last:</div>
                <div className="row text-secondary rounded rounded-3 mb-3 mx-0" style={{ 'background': '#222222' }}>
                    <div className="col-12">
                        {bettracekerLast.map(e => {
                            return (
                                <div>
                                    <div>
                                        {e.email.substring(0, e.email.indexOf('@'))} {e.value} {e.stake}€
                                    </div>
                                    <div>
                                        {e.name}
                                    </div>
                                    <div>
                                        {e.market} {e.bet}
                                    </div>
                                    <hr />
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="font1 text-light">Users:</div>
                {usersStats.map(e => {
                    const partner = partners.find(i => i.email === e.email)
                    const i = invoices.filter(e => e.partner_id[0] === partner?.id)
                    const unpaid = i.filter(e => e.payment_state == 'not_paid').map(e => e.amount_total).reduce((t, c) => t + c, 0)
                    console.log(e.email, partner, i)
                    return (
                        <div className="row text-secondary rounded rounded-3 mb-3 mx-0" style={{ 'background': '#222222' }}>
                            <div className="col-12">
                                {e.email} [{i ? i.map(e => e.amount_total).reduce((t, c) => t + c, 0) : 0}€] {unpaid && `${unpaid}€`}
                            </div>
                            <div className="col-12">
                                all: {e.profit.toFixed(2)}€ {e.roi.toFixed(2)}% [{e.count}]
                            </div>
                            <div className="col-12">
                                month: {e.month_profit.toFixed(2)}€ {e.month_roi.toFixed(2)}% [{e.month_count}]
                            </div>
                            <div className="col-12">
                                week: {e.week_profit.toFixed(2)}€ {e.week_roi.toFixed(2)}% [{e.week_count}]
                            </div>
                            <div className="col-12">
                                message: {partner?.user_message?.toString()}
                            </div>
                        </div>

                    )
                })}

                <div style={{ 'height': '1000px' }}></div>
            </div>
        </Layout >
    )
}

export default Admin
