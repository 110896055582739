import axios from "axios"
import { getCookie } from "../../helpers/helpers"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import { useEffect, useState } from "react";
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
const XLSX = require('xlsx')
const { getISOWeek } = require('date-fns')

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const sites = ['nike', 'tipsport', 'doxxbet', 'fortuna']
const sports = ['football', 'hockey', 'tennis', 'basketball', 'volleyball', 'handball']

export const validateBets = (parameters) => {
    const { bets } = parameters
    let error = false
    if (bets.length === 0) { return 'something went wrong' }
    for (const bet of bets) {
        if (!bet.account) { return 'Account is required' }
        if (bet.account.length < 1) { return 'Account is required' }
        if (!bet.bettype) { return 'Bet Type is required' }
        if (bet.bettype.length < 1) { return 'Bet Type is required' }
        if (!bet.game_type) { return 'Game type is required' }
        if (bet.game_type.length < 1) { return 'Game type is required' }
        if (!bet.site) { return 'Site is required' }
        if (bet.site.length < 1) { return 'Site is required' }
        if (!bet.name) { return 'Name is required' }
        if (bet.name.length < 1) { return 'Name is required' }
        if (!bet.start) { return 'Start is required' }
        if (bet.start.length < 1) { return 'Start is required' }
        if (!bet.market) { return 'Market is required' }
        if (bet.market.length < 1) { return 'Market is required' }
        if (!bet.bet) { return 'Bet is required' }
        if (bet.bet.length < 1) { return 'Bet is required' }
        if (!(bet.value)) { return 'Odds is required' }
        if (!(bet.stake)) { return 'Stake is required' }
        if (isNaN(bet.value)) { return 'Odds is not a number' }
        if (isNaN(bet.stake)) { return 'Stake is a number' }
        if ((bet.value) < 1) { return 'Odds is not correct' }
        if ((bet.stake) < 0) { return 'Stake is not correct' }
        if (bet.status.length < 1) { return 'Status is required' }
    }
    return null
}

export const updateBets = (parameters) => {
    const { fetchNewBets, bets, setError, setBets } = parameters
    const token = getCookie('token')
    const error = validateBets({ bets })
    setError('')
    if (error) { return setError(error) }
    axios.post(`${process.env.REACT_APP_API}/bettracker/update`, { token, bets })
        .then(response => {
            fetchNewBets()
            setBets([])

        })
        .catch(error => {
            console.log(error)
        })

}

export const deleteBets = (parameters) => {
    const { fetchNewBets, bets } = parameters
    const token = getCookie('token')
    axios.post(`${process.env.REACT_APP_API}/bettracker/delete`, { token, bets })
        .then(response => {
            fetchNewBets()
            window.location.reload()
        })
        .catch(error => {
            console.log(error)
        })
}

export const createBets = (parameters) => {
    const { setAddedToBetTracker, fetchNewBets, bets, setBets, setError,setSuccess } = parameters
    const token = getCookie('token')
    const error = validateBets({ bets })
    setError('')
    if (error) { return setError(error) }
    axios.post(`${process.env.REACT_APP_API}/bettracker/create`, { token, bets })
        .then(response => {
            if (fetchNewBets) {
                fetchNewBets()
            }
            if (setBets) {
                setBets([])
            }
            if (setAddedToBetTracker) {
                setAddedToBetTracker(true)
                console.log('setAddedToBetTracker')
            }
            if(setSuccess){
                setSuccess('Successfully added to BetTracker')
            }
            return 'added'
        })
        .catch(error => {
            console.log(error)
        })
}

export const BetsModal = ({ parameters }) => {
    const { bets, setBets, fetchNewBets, accounts } = parameters
    const [error, setError] = useState(null)
    const [helpMarket, setHelpMarket] = useState(false)
    const [helpBet, setHelpBet] = useState('')
    const [p0, setP0] = useState('')
    const [p1, setP1] = useState('')
    const [p2, setP2] = useState('')
    const [p3, setP3] = useState('')
    const [p4, setP4] = useState('')
    const [market, setMarket] = useState("")


    const setToAll = (key, value) => {
        console.log(key, value)
        setBets(e => e.map(bet => ({ ...bet, [key]: value })))
    }
    const setToOne = (objectIndex, key, value) => {
        setBets(bets =>
            bets.map((obj, index) =>
                index === objectIndex ? { ...obj, [key]: value } : obj
            )
        )
    }

    useEffect(() => { setP2(''); setP3(''); setP4('') }, [p1])
    useEffect(() => { setP3(''); setP4('') }, [p2])
    useEffect(() => { setP4('') }, [p3])
    useEffect(() => { setP0(''); setP1(''); setP2(''); setP3(''); setP4('') }, [helpMarket])

    useEffect(() => {
        setMarket([p0, p1, p2, p3, p4].filter(value => value !== null && value !== undefined && value !== '').join(' '))
        setToAll('market', market)
    }, [p0, p1, p2, p3, p4])

    const marketSchema = {
        'p0': ['tim1', 'tim2'],
        'p0_for': ['počet', 'handicap'],
        'p1': ['počet', 'zápas', 'stávka bez remízy', 'víťaz', 'postup', 'obaja dajú gól', 'súčet gamov', 'tiebreak', 'kanár', 'handicap', 'penalta v zápase'],
        'p2': {
            'počet': ['gólov', 'rohov', 'striel', 'ofsajdov', 'faulov', 'dvojchyb', 'gemov', 'es', 'setov', '2 min. trestov', 'bodov'],
            'handicap': ['góly', 'body', 'sety', 'gamy', 'dvojchyby', 'esa']
        },
        'p2_for': ['počet', 'handicap'],
        'p3': {
            'počet': ['vrátane predĺženia', 'v 1.', 'v 2.', 'v 3.'],
            'víťaz': ['zápasu', 'vrátane predĺženia', '1.', '2.', '3.'],
            'stávka bez remízy': ['1.', '2.', '3.', '4.']
        },
        'p4': {
            'počet': ['polčase', 'tretine', 'sete', 'štvrtine'],
            'víťaz': ['polčas', 'tretina', 'set', 'štvrtina'],
            'stávka bez remízy': ['polčas', 'tretina', 'set', 'štvrtina']
        }
    }

    const fontSizeMarket = window.innerWidth >= 650 ? '1rem' : (window.innerWidth >= 450 ? '0.8rem' : '0.5rem')

    return (
        <>
            <Modal
                onHide={() => setBets([])}
                show={bets.length > 0}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="placeholder-color-white"
                style={{ '--bs-modal-width': '100%', '--bs-modal-bg': '#222', '--bs-body-bg': '#222', '--bs-body-color': '#fff', '--bs-border-color': '#616161' }}
            >
                <Modal.Header closeButton style={{ 'background': '#222', 'border': 'none' }} className="text-light">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {(bets.length === 1 && bets[0].id) && `Edit ${bets[0].bettype} bet`}
                        {(bets.length === 1 && !bets[0].id) && `Create ${bets[0].bettype} bet`}
                        {(bets.length === 2 && !bets[0].id && !bets[1].id) && `Create ${bets[0].bettype} bet`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ 'background': '#222' }}>
                    {bets.length > 0 && (
                        <>
                            <div className="row mb-3">
                                <div className="col-6 col-lg mt-2 ">
                                    <Form.Control className="placeholder-color-white" as="select" placeholder="Game Type" value={bets[0].bettype || ""} onChange={e => setToAll("bettype", e.target.value)} >
                                        <option value="prematch">prematch</option>
                                        <option value="live">live</option>
                                    </Form.Control>
                                </div>
                                <div className="col-6 col-lg mt-2 ">
                                    <Form.Control className="placeholder-color-white" type="text" placeholder="Name" value={bets[0].name || ""} onChange={e => setToAll("name", e.target.value)} />
                                </div>
                                <div className="col-6 col-lg mt-2 ">
                                    <Form.Control className="placeholder-color-white" type="text" placeholder="Competition" value={bets[0].competition || ""} onChange={e => setToAll("competition", e.target.value)} />
                                </div>
                                <div className="col-6 col-lg mt-2 ">
                                    <Form.Control className="placeholder-color-white" type="text" placeholder="Sport" value={bets[0].sport || ""} onChange={e => setToAll("sport", e.target.value)} list="options_sports" />
                                    <datalist id="options_sports">
                                        {sports.map(e => {
                                            return (
                                                <option key={e} value={e} onClick={e => setToAll("sport", e.target.value)} />
                                            )
                                        })}
                                    </datalist>
                                </div>
                                <div className="col-12 col-lg mt-2 ">
                                    <input
                                        type="datetime-local"
                                        className="form-control"
                                        value={(bets[0].start ? toIsoStringLocal(new Date(bets[0].start)).slice(0, 16) : "")}
                                        onChange={e => setToAll("start", new Date(e.target.value).toISOString())}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-lg-9 mt-2">
                                    <Form.Control className="placeholder-color-white" type="text" placeholder="Market" value={bets[0].market || ""} onChange={e => setToAll("market", e.target.value)} />
                                </div>
                                <div className="col-12 col-lg-3 mt-2">
                                    <div className="w-100 btn btn-warning" onClick={() => setHelpMarket(true)}>Help Create Market</div>
                                </div>
                            </div>
                            <div className={`text-light ps-1 ${bets.length < 2 && 'd-none'} `}>First Bet:</div>
                            <div className="row mb-3">
                                <div className="col-9 col-lg pe-0 mt-2">
                                    <Form.Control className="placeholder-color-white" type="text" placeholder="Bet" value={bets[0].bet || ""} onChange={e => setToOne(0, "bet", e.target.value)} />
                                </div>
                                <div className="ps-1 col-3 col-lg-auto mt-2">
                                    <div className={`w-100 btn btn-warning`} onClick={() => setHelpBet(1)}>?</div>
                                </div>
                                <div className="col-6 col-lg mt-2">
                                    <Form.Control className="placeholder-color-white" list="options_site1" type="text" placeholder="Site" value={bets[0].site || ""} onChange={e => setToOne(0, "site", e.target.value)} />
                                    <datalist id="options_site1">
                                        {[...new Set(accounts.map(e => e.site))].map(e => {
                                            return (
                                                <option key={e} value={`${e}`} />
                                            )
                                        })}
                                    </datalist>
                                </div>
                                <div className="col-6 col-lg mt-2">
                                    <Form.Control className="placeholder-color-white" list="options_account1" type="text" placeholder="Account" value={bets[0].account || ""} onChange={e => setToOne(0, "account", e.target.value)} />
                                    <datalist id="options_account1">
                                        {accounts.filter(e => e.site === bets[0].site).map(e => {
                                            return (
                                                <option key={e.account} value={`${e.account}`} />
                                            )
                                        })}
                                    </datalist>
                                </div>
                                <div className="col-6 col-lg mt-2">
                                    <Form.Control className="placeholder-color-white" type="text" placeholder="Odds" value={bets[0].value || ""} onChange={e => setToOne(0, "value", e.target.value)} />
                                </div>
                                <div className="col-6 col-lg mt-2">
                                    <Form.Control className="placeholder-color-white" type="text" placeholder="Stake" value={bets[0].stake || ""} onChange={e => setToOne(0, "stake", e.target.value)} />
                                </div>
                            </div>
                            <div className={`text-light ps-1 ${bets.length < 2 && 'd-none'} `}>Second Bet:</div>
                            {bets.length === 2 &&
                                <div className="row mb-3">
                                    <div className="col-9 col-lg pe-0 mt-2">
                                        <Form.Control className="placeholder-color-white" type="text" placeholder="Bet" value={bets[1].bet || ""} onChange={e => setToOne(1, "bet", e.target.value)} />
                                    </div>
                                    <div className="ps-1 col-3 col-lg-auto mt-2">
                                        <div className={`w-100 btn btn-warning`} onClick={() => setHelpBet(2)}>?</div>
                                    </div>
                                    <div className="col-6 col-lg mt-2">
                                        <Form.Control className="placeholder-color-white" list="options_site2" type="text" placeholder="Site" value={bets[1].site || ""} onChange={e => setToOne(1, "site", e.target.value)} />
                                        <datalist id="options_site2">
                                            {[...new Set(accounts.map(e => e.site))].map(e => {
                                                return (
                                                    <option key={e} value={`${e}`} />
                                                )
                                            })}
                                        </datalist>
                                    </div>
                                    <div className="col-6 col-lg mt-2">
                                        <Form.Control className="placeholder-color-white" list="options_account2" type="text" placeholder="Account" value={bets[1].account || ""} onChange={e => setToOne(1, "account", e.target.value)} />
                                        <datalist id="options_account2">
                                            {accounts.filter(e => e.site === bets[1].site).map(e => {
                                                return (
                                                    <option key={e.account} value={`${e.account}`} />
                                                )
                                            })}
                                        </datalist>
                                    </div>
                                    <div className="col-6 col-lg mt-2">
                                        <Form.Control className="placeholder-color-white" type="text" placeholder="Odds" value={bets[1].value || ""} onChange={e => setToOne(1, "value", e.target.value)} />
                                    </div>
                                    <div className="col-6 col-lg mt-2">
                                        <Form.Control className="placeholder-color-white" type="text" placeholder="Stake" value={bets[1].stake || ""} onChange={e => setToOne(1, "stake", e.target.value)} />
                                    </div>
                                </div>
                            }
                            <div className="row mb-3">
                                <div className="col">
                                    <Form.Control className="placeholder-color-white" type="text" placeholder="Note" value={bets[0].note || ""} onChange={e => setToAll("note", e.target.value)} />
                                </div>
                            </div>
                        </>
                    )}

                </Modal.Body>
                <Modal.Footer style={{ 'background': '#222', 'border': 'none' }}>
                    {error}
                    {(bets.length > 0 && bets[0].id) &&
                        <>
                            <Button className="btn btn-danger" onClick={() => { deleteBets({ fetchNewBets, bets }); setBets([]) }}>Delete</Button>
                            <Button className="btn btn-primary" onClick={() => { updateBets({ setError, fetchNewBets, bets, setBets }) }}>Save</Button>
                        </>
                    }
                    {(bets.length > 0 && !bets[0].id) &&
                        <>
                            <Button className="btn btn-primary" onClick={() => { createBets({ setError, fetchNewBets, bets, setBets }) }}>Create</Button>
                        </>
                    }
                </Modal.Footer>
            </Modal>
            {/* create market */}
            <Modal
                show={helpMarket} onHide={() => setHelpMarket(false)} aria-labelledby="contained-modal-title-vcenter" centered size='xl'
                style={{ 'zIndex': '10000', '--bs-modal-bg': '#222', '--bs-body-bg': '#222', '--bs-body-color': '#fff', '--bs-modal-width': '90%' }}>
                <Modal.Header closeButton style={{ 'background': '#222', 'border': 'none' }}>
                    <Modal.Title id="contained-modal-title-vcenter" className="text-light">
                        Create Market Name{market && ` - `}{market && <span className="text-secondary">{market}</span>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ 'background': '#222', '--bs-btn-bg': '#fff' }} className="p-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className={`col p-1 d-flex flex-column `} style={{ 'maxWidth': 'calc(100%/5)' }}>
                                {marketSchema.p0.map(e => {
                                    return (
                                        <div
                                            className={`my-1 btn text-truncate ${(!marketSchema.p0_for.includes(p1) && p1 != '') && 'disabled'} px-0`}
                                            style={{ 'fontSize': fontSizeMarket, '--bs-btn-disabled-opacity': '0.2', 'background': '#161616', 'border': 'none', 'color': `${p0 == e ? '#0d6efd' : '#fff'}` }}
                                            onClick={() => setP0(p0 !== e ? e : '')}>
                                            {e}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="col p-1 d-flex flex-column" style={{ 'maxWidth': 'calc(100%/5)' }}>
                                {marketSchema.p1.map(e => {
                                    return (
                                        <div
                                            className={`my-1 btn text-truncate ${(p0 !== '' && !marketSchema.p0_for.includes(e)) && 'disabled'} `}
                                            style={{ 'fontSize': fontSizeMarket, '--bs-btn-disabled-opacity': '0.2', 'background': '#161616', 'border': 'none', 'color': `${p1 == e ? '#0d6efd' : '#fff'}` }}
                                            onClick={() => setP1(p1 !== e ? e : '')}>
                                            {e}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={`col p-1 d-flex flex-column ${!marketSchema.p2_for.includes(p1) && 'd-none'} `} style={{ 'maxWidth': 'calc(100%/5)' }}>
                                {marketSchema.p2[p1] && marketSchema.p2[p1].map(e => {
                                    return (
                                        <div
                                            className={`my-1 btn text-truncate`}
                                            style={{ 'fontSize': fontSizeMarket, '--bs-btn-disabled-opacity': '0.2', 'background': '#161616', 'border': 'none', 'color': `${p2 == e ? '#0d6efd' : '#fff'}` }}
                                            onClick={() => setP2(p2 != e ? e : '')}>
                                            {e}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={`col p-1 d-flex flex-column  ${(false) && 'd-none'} `} style={{ 'maxWidth': 'calc(100%/5)' }}>
                                {marketSchema.p3[p1] && marketSchema.p3[p1].map(e => {
                                    return (
                                        <div
                                            className={`my-1 btn text-truncate`}
                                            style={{ 'fontSize': fontSizeMarket, '--bs-btn-disabled-opacity': '0.2', 'background': '#161616', 'border': 'none', 'color': `${p3 == e ? '#0d6efd' : '#fff'}` }}
                                            onClick={() => setP3(p3 != e ? e : '')}>
                                            {e}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={`col p-1 d-flex flex-column ${false && 'd-none'}`} style={{ 'maxWidth': 'calc(100%/5)' }}>
                                {(p3.includes('.') && marketSchema.p4[p1]) && marketSchema.p4[p1].map(e => {
                                    return (
                                        <div
                                            className={`my-1 btn text-truncate`}
                                            style={{ 'fontSize': fontSizeMarket, '--bs-btn-disabled-opacity': '0.2', 'background': '#161616', 'border': 'none', 'color': `${p4 == e ? '#0d6efd' : '#fff'}` }}
                                            onClick={() => setP4(p4 !== e ? e : '')}>
                                            {e}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer style={{ 'background': '#222', 'border': 'none' }}>
                    <div className="d-flex justify-content-between w-100">
                        <div className="text-light">

                        </div>
                        <div>
                            <Button variant="primary" onClick={() => setHelpMarket(false)}>Close</Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* create bet */}
            <Modal
                show={[1, 2].includes(helpBet)} onHide={() => setHelpBet(false)} aria-labelledby="contained-modal-title-vcenter" centered size="sm"
                style={{ 'zIndex': '10000', '--bs-modal-bg': '#222', '--bs-body-bg': '#222', '--bs-body-color': '#fff', '--bs-modal-width': '90%' }}>
                <Modal.Header closeButton style={{ 'background': '#222', 'border': 'none' }}>
                    <Modal.Title id="contained-modal-title-vcenter" className="text-light">
                        Create Bet
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ 'background': '#222', '--bs-btn-bg': '#fff' }} className="p-1">
                    <div className="container-fluid">
                        <div className="row">
                            {['menej ako ', 'viac ako ', 'domáci', 'hosť', 'neprehra domáci', 'neprehra hosť', 'remíza', 'nebude remíza', 'áno', 'nie', 'pár', 'nepár'].map(e => {
                                return (
                                    <div key={e} className="col-6 text-center">
                                        <div
                                            className="btn  my-1 px-0"
                                            style={{ 'width': '100%','background':'#161616','fontSize':'0.8rem' }}
                                            onClick={() => {setToOne(helpBet-1,'bet',e);setHelpBet(false)}}
                                        >{e}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer style={{ 'background': '#222', 'border': 'none' }}>
                    <div className="d-flex justify-content-between w-100">
                        <div className="text-light">

                        </div>
                        <div>
                            <Button variant="primary" onClick={() => setHelpBet(false)}>Close</Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export const createTransaction = (parameters) => {
    const { setError, fetchNewTransactions, transaction, setTransaction } = parameters
    const { transaction_type, site, account, date, value } = transaction

    const token = getCookie('token')
    if (!transaction_type) { return setError("type is required") }
    if (!site) { return setError("site  is required") }
    if (!account) { return setError("account is required") }
    if (!date) { return setError("date is required") }
    if (!value) { return setError("value is required") }
    if (value <= 0) { return setError("Value must be > 0") }

    if (transaction_type && site && account && date && value > 0) {
        axios.post(`${process.env.REACT_APP_API}/transactiontracker/create`, { token, transaction_type, site, account, date, value })
            .then(response => {
                setError('')
                setTransaction()
                fetchNewTransactions()
            })
            .catch(error => {
                setError(error.response.data.error)
            })
    } else {
        setError("Invalid values")
    }
}

export const deleteTransaction = (parameters) => {
    const { fetchNewTransactions, bets, id } = parameters
    const token = getCookie('token')
    axios.post(`${process.env.REACT_APP_API}/transactiontracker/delete`, { token, id })
        .then(response => {
            fetchNewTransactions()
        })
        .catch(error => {
            console.log(error)
        })
}

export const TransactionsModal = ({ parameters }) => {
    const { transaction, setTransaction, fetchNewTransactions, accounts } = parameters
    const [error, setError] = useState(null)

    return (
        <Modal
            onHide={() => setTransaction()}
            show={transaction}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create New Transaction
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {transaction && (
                    <>
                        <div className="row mb-3">
                            <div className="col-4 col-lg-2 mt-3">
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Control as="select" onChange={e => setTransaction({ ...transaction, transaction_type: e.target.value })}>
                                        <option value="deposit">deposit</option>
                                        <option value="withdraw">withdraw</option>
                                        <option value="bonus">bonus</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col-4 col-lg-2 mt-3">
                                <Form.Control list="options_site" type="text" placeholder="Site" value={transaction.site || ""} onChange={e => setTransaction({ ...transaction, site: e.target.value })} />
                                <datalist id="options_site">
                                    {[...new Set(accounts.map(e => e.site))].map(e => {
                                        return (
                                            <option key={e} value={`${e}`} />
                                        )
                                    })}
                                </datalist>
                            </div>
                            <div className="col-4 col-lg-3 mt-3">
                                <Form.Control list="options_account" type="text" placeholder="Account" value={transaction.account || ""} onChange={e => setTransaction({ ...transaction, account: e.target.value })} />
                                <datalist id="options_account">
                                    {transaction.site && accounts.filter(e => e.site === transaction.site).map(e => {
                                        return (
                                            <option key={e.account} value={`${e.account}`} />
                                        )
                                    })}
                                </datalist>
                            </div>
                            <div className="col-6 col-lg-3 mt-3">
                                <Form.Control className="form-control" type="date" placeholder="Date" onChange={e => setTransaction({ ...transaction, date: new Date(e.target.value).toISOString() })} />

                            </div>
                            <div className="col-6 col-lg-2 mt-3">
                                <Form.Control type="number" placeholder="Value" value={transaction.value || ""} onChange={e => setTransaction({ ...transaction, value: e.target.value })} />
                            </div>
                        </div>
                    </>
                )}

            </Modal.Body>
            <Modal.Footer>
                {error}
                <Button className="btn btn-primary" onClick={() => { createTransaction({ setError, fetchNewTransactions, transaction, setTransaction }) }}>Create</Button>
            </Modal.Footer>
        </Modal>
    )

}

export const toIsoStringLocal = (date) => {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
}

export const formatIsoString = (date) => {
    return {
        'day': days[(new Date(date)).getDay()],
        'date': new Date(date).getDate(),
        'month': months[(new Date(date)).getMonth()],
        'hour': (new Date(date)).getHours(),
        'minute': ((new Date(date)).getMinutes() < 10 ? '0' : '') + (new Date(date)).getMinutes(),
        'dateText': (
            (new Date(date).getDate() === new Date().getDate() &&
                new Date(date).getMonth() === new Date().getMonth())
                ? 'DNES'
                : (new Date(date).getDate() === new Date(new Date().setDate(new Date().getDate() + 1)).getDate() &&
                    new Date(date).getMonth() === new Date(new Date().setDate(new Date().getDate() + 1)).getMonth())
                    ? 'ZAJTRA'
                    : `${new Date(date).getDate()} ${months[(new Date(date)).getMonth()]}`
        )
    }
}

export const defaultBet = {
    "account": null,
    "bettype": null,
    "game_type": "prematch",
    "site": null,
    "name": null,
    "market": null,
    "bet": null,
    "value": null,
    "stake": null,
    "start": null,
    "sport": null,
    "status": "pending",
    "sold_price": 0,
}

export const defaultTransaction = {
    "account": "",
    "site": "",
    "value": "",
    "date": "",
    "transaction_type": "deposit",
}

export const DashboardBettype = ({ parameters }) => {
    const { profits_by_bettype } = parameters

    if (!profits_by_bettype) { return '' }

    return (
        <div>
            {profits_by_bettype.map(e => {
                return (
                    <div key={e.bettype}>
                        {e.bettype} {['deposit', 'withdraw'].includes(e.bettype) ? (e.bettype === 'withdraw') ? e.balance.toFixed(2) * -1 : e.balance.toFixed(2) : e.profit.toFixed(2)}
                    </div>
                )
            })}
        </div>
    )
}

export const getMonthInfo = (year, month) => {
    const days = []
    const daysInMonth = new Date(year, month + 1, 0).getDate()

    for (let d = 1; d < daysInMonth + 1; d++) {
        const day = new Date(year, month, d).getDay()
        const week = getISOWeek(new Date(year, month, d))

        days.push({ date: d, day, week })
    }

    console.log(days)
    return days
}

export const DashboardCalendar = ({ parameters }) => {
    const [month, setMonth] = useState((new Date()).getMonth())
    const [year, setYear] = useState((new Date()).getFullYear())
    const { profits_by_days } = parameters
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const daysInMonth = getMonthInfo(year, month)

    const totalProfit = parseFloat(profits_by_days.filter(e => e.month_number - 1 === month && e.year_number === year).map(e => e.profit).reduce((t, c) => t + c, 0).toFixed(2))

    console.log(totalProfit)

    return (
        <div className="row no-select">
            <div className="col-12 d-flex text-secondary fs-4 font1" style={{ 'height': '100px' }}>
                <div className="w-50">
                    <div className="ps-3 pt-3 d-flex">
                        <div
                            onClick={() => { if (month !== 0) { setMonth(month - 1) } else { setMonth(11); setYear(year - 1) } }}
                            className="rounded rounded-3 d-flex justify-content-center align-items-center" style={{ 'backgroundColor': '#343434', 'height': '32px', 'width': '32px', 'cursor': 'pointer' }}>
                            <MdOutlineKeyboardDoubleArrowLeft size="70%" />
                        </div>
                        <div style={{ 'width': '8px' }}></div>
                        <div
                            onClick={() => { if (month !== 11) { setMonth(month + 1) } else { setMonth(0); setYear(year + 1) } }}
                            className="rounded rounded-3 d-flex justify-content-center align-items-center" style={{ 'backgroundColor': '#343434', 'height': '32px', 'width': '32px', 'cursor': 'pointer' }}>
                            <MdOutlineKeyboardDoubleArrowRight size="70%" />
                        </div>
                        <div style={{ "position": 'absolute', 'bottom': '10px', 'right': '10px', 'color': '#3a3a3a' }}>
                            <div className="lh-1" style={{ 'fontSize': '1rem' }}>
                                {year}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-50 text-end pe-3 pt-3">
                    <div className="lh-1" style={{ 'fontSize': "2.5rem" }}>
                        {months[month]}
                    </div>
                    <div
                        style={{ 'fontSize': "1.5rem" }}
                        className={`lh-1 ${totalProfit > 0 && 'text-success'} ${totalProfit < 0 && 'text-danger'} ${totalProfit == 0 && 'text-secondary'}`}
                    >{totalProfit}€
                    </div>
                </div>
                {/* <div
                    onClick={() => { if (month !== 0) { setMonth(month - 1) } else { setMonth(11); setYear(year - 1) } }}
                ><MdOutlineKeyboardDoubleArrowLeft /></div>
                <div className="d-flex justify-content-center align-items-center flex-column" style={{'width':'90px'}}>
                    <div className="lh-1">{months[month]}</div>
                </div>
                <div
                    onClick={() => { if (month !== 11) { setMonth(month + 1) } else { setMonth(0); setYear(year + 1) } }}
                ><MdOutlineKeyboardDoubleArrowRight /></div>
                <div>{totalProfit}</div> */}
            </div>
            <div className="col-12 font1" style={{ 'height': '350px' }}>
                <div className="div">
                    <div className="row mx-3" style={{ 'height': '30px' }}>
                        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(e => {
                            return (
                                <div className="col text-center" key={e}>{e}</div>
                            )
                        })}
                    </div>
                    {[...new Set(daysInMonth.map(e => e.week))].map(week => {
                        return (
                            <div className="row mx-3" style={{ 'height': 'calc(310px/6)', 'borderTop': '1px solid #3a3a3a' }}>
                                {[1, 2, 3, 4, 5, 6, 0].map(day => {
                                    const d = daysInMonth.find(e => e.week === week && e.day === day)
                                    const today = (new Date()).getMonth() === month && (new Date()).getFullYear() === year && (new Date()).getDate() === d?.date
                                    const profit = profits_by_days.find(e => e.month_number - 1 === month && e.year_number === year && e.day_number === d?.date)
                                    const day_profit = Math.ceil(profit?.profit) || 0

                                    return (
                                        <div className="col text-center px-0">
                                            <div className={`d-flex flex-column ${day_profit > 0 && 'bg-success'} ${day_profit < 0 && 'bg-danger'}`} style={{ '--bs-bg-opacity': '0.2' }}>
                                                <div
                                                    className={`${today && 'text-info'} d-flex justify-content-center align-items-center`}
                                                    style={{ "height": 'calc(310px/12)' }}
                                                >{d?.date || ''}
                                                </div>
                                                <div
                                                    className={`font2 d-flex justify-content-center align-items-center ${day_profit > 0 && 'text-success'} ${day_profit < 0 && 'text-danger'}`}
                                                    style={{ "height": 'calc(310px/12)', 'fontSize': '1.1rem' }}
                                                >{day_profit != 0 && day_profit + "€"}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })
                    }

                </div>
            </div>
        </div>
    )
}

export const exportToExcel = (parameters) => {
    const { bets, transactions } = parameters

    const wsBets = XLSX.utils.json_to_sheet(bets)
    const wsTransactions = XLSX.utils.json_to_sheet(transactions)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, wsBets, 'Bets')
    XLSX.utils.book_append_sheet(wb, wsTransactions, 'Transactions')

    const now = new Date()
    const filename = `BetTracker_${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}.xlsx`

    XLSX.writeFile(wb, filename)
}