import { useNavigate, BrowserRouter as Router, Route } from "react-router-dom";
import Layout from "./components/Layout";
import { useEffect } from "react";


const App = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/prematch/arbitrage');
  }, [navigate])
  return (
    <Layout>
    </Layout>
  );
}

export default App;
