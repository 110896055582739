import { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { getCookie, isAuth, setCookie } from '../../helpers/helpers'
import axios from "axios"
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import { defaultOptions, FreeVersion, UnpaidInvoices, UserMessage, formatIsoString, roundDigits, Button, hideGroup, hideGame, Disclamer, invalidGame, Options, NothingFound, Bonuses } from './helpers'
import ClipLoader from "react-spinners/ClipLoader";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { BiLineChart, BiHide, BiRefresh, BiDotsHorizontalRounded } from 'react-icons/bi'
import { MdLink, MdLinkOff, MdClose, MdDeleteForever, MdOutlineEditOff, MdOutlineEdit, MdPhotoSizeSelectLarge, MdInfoOutline } from 'react-icons/md'
import { RiEmotionSadLine } from 'react-icons/ri'
import { LuWallet } from 'react-icons/lu'
import { createBets } from '../BetTracker/helpers'


const SmallScreen = ({ parameters }) => {
    const { arbitrages, free, unpaidInvoices, userMessage, selected, setSelected, values } = parameters
    const { sure, setSure, setError, addedToBetTracker, setAddedToBetTracker, options, setOptions, showAccounts, setShowAccounts, accounts, setAccounts, account1, setAccount1, account2, setAccount2, changingValues, setChangingValues, lastStake, setLastStake, linked, setLinked, value2, setValue2, value1, setValue1, stake2, setStake2, stake1, setStake1 } = values
    const [showMore, setShowMore] = useState(false)
    const [showHide, setShowHide] = useState(false)
    const [showInfo, setShowInfo] = useState(false)
    const navigate = useNavigate()


    useEffect(() => { setShowMore(false); setShowInfo(false) }, [selected])


    return (
        <>
            {/* <Bonuses /> */}
            <FreeVersion free={free} />
            <UnpaidInvoices unpaidInvoices={unpaidInvoices} />
            <UserMessage userMessage={userMessage} />
            <NothingFound arbitrages={arbitrages} />
            <Options options={options} setOptions={setOptions} />
            {arbitrages.map(arbitrage => {
                const { offer1_gameid, offer2_gameid, offer1_start, id, group_key, roi, offer1_url, offer2_url, offer1_site, offer2_site, offer1_home, offer2_away, market, market_quantity, offer1_bet, offer2_bet, offer1_color_primary, offer2_color_primary } = arbitrage
                const start = formatIsoString(offer1_start)

                const createdAgo = (Math.abs(new Date().getTime() - new Date(arbitrage.createdAt).getTime()) / 1000).toFixed(0)
                const updatedAgo = (Math.abs(new Date().getTime() - new Date(arbitrage.updatedAt).getTime()) / 1000).toFixed(0)


                return (
                    <div
                        key={id}
                        className="mx-sm-2 mt-2 mt-sm-3 p-3 p-sm-4 rounded-4 mt-2"
                        style={{ 'backgroundColor': '#222222', 'border': `3px solid ${group_key === selected ? '#fff' : '#222222'}` }}
                        onClick={() => (group_key != selected && setSelected(group_key))}>
                        <div className="container-fluid">
                            {/* roi,site,name */}
                            <div className="row" style={{ 'minHeight': '50px' }}>
                                <div className="col-3 p-0 d-flex align-items-center fs-1 font1">
                                    {roi.toFixed(2)}%
                                </div>
                                <div className="col-9 d-flex flex-column justify-content-center">
                                    <div className="row ps-1 lh-sm lh-sm-base">
                                        <div className="col p-0 d-flex justify-content-center" style={{ maxWidth: '45px' }}>
                                            <Link to={offer1_url} target="_blank" className="d-flex align-items-center">
                                                <img src={require(`../../static/images/sites/${offer1_site}.png`)} style={{ 'borderRadius': '2px' }} className="w-100" alt="Offer 1"></img>
                                            </Link>
                                        </div>
                                        <div className="col text-truncate font1">{offer1_home}</div>
                                    </div>
                                    <div className="row ps-1 lh-sm">
                                        <div className="col p-0 d-flex justify-content-center" style={{ maxWidth: '45px' }}>
                                            <Link to={offer2_url} target="_blank" className="d-flex align-items-center">
                                                <img src={require(`../../static/images/sites/${offer2_site}.png`)} style={{ 'borderRadius': '2px' }} className="w-100" alt="Offer 1"></img>
                                            </Link>
                                        </div>
                                        <div className="col text-truncate font1">{offer2_away}</div>
                                    </div>
                                </div>
                            </div>
                            {/* start,market */}
                            <div className="row" style={{ 'minHeight': '25px' }}>
                                <div className="col-3 p-0 text-truncate">
                                    <span className="text-secondary font1">
                                        {start.dateText} {start.hour}:{start.minute}
                                    </span>
                                </div>
                                <div className="col-9 p-0 ps-1 text-truncate d-flex align-items-center">
                                    <span className="text-secondary text-uppercase font1">
                                        {market}{market_quantity}
                                    </span>
                                </div>
                            </div>
                            {/* selected */}
                            <div className={`${group_key !== selected && 'd-none'}`}>
                                {/* dividing line */}
                                <div className="row my-3">
                                    <div style={{ 'height': '3px', 'width': '100%', 'backgroundColor': '#2a2a2a' }} />
                                </div>
                                {/* bet */}
                                <div className="row">
                                    <div className="col text-center pe-0">
                                        {offer1_bet}
                                    </div>
                                    <div className="col-auto p-0" style={{ 'width': '20%' }}></div>
                                    <div className="col text-center ps-0">
                                        {offer2_bet}
                                    </div>
                                </div>
                                {/* value */}
                                <div className="row">
                                    <div className="col fs-2 text-center pe-0 font1" style={{ 'color': offer1_color_primary }}>
                                        {changingValues
                                            ?
                                            <input
                                                value={value1}
                                                onChange={e => { setValue1(e.target.value) }}
                                                name='value1'
                                                type="number"
                                                min="1"
                                                step={"0.1"}
                                                className="form-control text-center placeholder-color-white p-0 text1"
                                                placeholder="€"
                                                style={{ "height": '38px', "background": "#222222", 'fontSize': "20px", "boxShadow": `none`, 'borderRadius': '0.8em', 'border': `3px solid ${offer1_color_primary}`, "color": offer1_color_primary }}
                                            ></input>
                                            : <>{value1}</>
                                        }
                                    </div>
                                    <div className="col-auto p-0 d-flex justify-content-center align-items-center" style={{ 'width': '20%' }}>
                                        <div className="d-flex justify-content-center align-items-center" style={{ 'backgroundColor': '#161616', 'width': '45px', "height": '28px', 'borderRadius': '8px' }}>
                                            {changingValues
                                                ? <BiRefresh onClick={() => setChangingValues(!changingValues)} size={"100%"} style={{ 'maxWidth': '22px' }} />
                                                : <MdOutlineEdit onClick={() => setChangingValues(!changingValues)} size={"100%"} style={{ 'maxWidth': '20px' }} />
                                            }
                                        </div>
                                    </div>
                                    <div className="col fs-2 text-center ps-0 font1" style={{ 'color': offer2_color_primary }}>
                                        {changingValues
                                            ?
                                            <input
                                                value={value2}
                                                onChange={e => { setValue2(e.target.value) }}
                                                name='value2'
                                                type="number"
                                                min="1"
                                                step={"0.1"}
                                                className="form-control text-center placeholder-color-white p-0 text1"
                                                placeholder="€"
                                                style={{ "height": '38px', "background": "#222222", 'fontSize': "20px", "boxShadow": `none`, 'borderRadius': '0.8em', 'border': `3px solid ${offer2_color_primary}`, "color": offer2_color_primary }}
                                            ></input>
                                            : <>{value2}</>
                                        }
                                    </div>
                                </div>
                                {/* account */}
                                <div className={`row pt-3 pb-1 ${!showAccounts && 'd-none'}`}>
                                    <div className="col text-center pe-0 font1">
                                        <input
                                            value={account1}
                                            onChange={e => { setAccount1(e.target.value) }}
                                            name='account1'
                                            type="text"
                                            className="form-control text-center placeholder-color-white p-0 text1 text-secondary text-truncate"
                                            placeholder="Enter Account Name"
                                            list='datalist_account1'
                                            style={{ 'background': 'transparent', 'border': 'none', 'boxShadow': 'none' }}
                                        />
                                        <datalist id='datalist_account1'>
                                            {accounts.filter(e => e.site === offer1_site).map(e => {
                                                return (
                                                    <option id={e.name} value={`${e.account}`} label={`${e.balance.toFixed(2)}€`} />
                                                )
                                            })}
                                        </datalist>
                                    </div>
                                    <div className="col-auto p-0 text-truncate text-uppercase text-secondary d-flex justify-content-center align-items-center font1" style={{ 'width': '20%', 'fontSize': '16px' }}>account</div>
                                    <div className="col text-center ps-0 font1">
                                        <input
                                            value={account2}
                                            onChange={e => { setAccount2(e.target.value) }}
                                            name='account2'
                                            type="text"
                                            className="form-control text-center placeholder-color-white p-0 text1 text-secondary text-truncate"
                                            placeholder="Enter Account Name"
                                            list='datalist_account2'
                                            style={{ 'background': 'transparent', 'border': 'none', 'boxShadow': 'none' }}
                                        />
                                        <datalist id='datalist_account2'>
                                            {accounts.filter(e => e.site === offer2_site).map(e => {
                                                return (
                                                    <option id={e.name} value={`${e.account}`} label={`${e.balance.toFixed(2)}€`} />
                                                )
                                            })}
                                        </datalist>
                                    </div>
                                </div>
                                {/* available */}
                                <div className={`row ${!showAccounts && 'd-none'}`}>
                                    <div className={`col text-center pe-0 text-secondary font1 ${!showAccounts && 'd-none'}`}>
                                        {roundDigits(accounts.find(e => e.site === offer1_site && e.account === account1)?.balance || 0)}€
                                    </div>
                                    <div className="col-auto p-0 text-truncate text-uppercase text-secondary d-flex justify-content-center align-items-center font1" style={{ 'width': '20%', 'fontSize': '16px' }}>available</div>
                                    <div className="col text-center ps-0 text-secondary font1">
                                        {roundDigits(accounts.find(e => e.site === offer2_site && e.account === account2)?.balance || 0)}€
                                    </div>
                                </div>
                                {/* stake */}
                                <div className="row my-4">
                                    <div className="col text-center pe-0">
                                        <input
                                            value={stake1}
                                            onChange={e => { setStake1(e.target.value); setLastStake(e.target.name) }}
                                            name='stake1'
                                            type="number"
                                            min="0"
                                            step={"10"}
                                            className="form-control text-center text-white placeholder-color-white p-0 text1"
                                            placeholder="€"
                                            style={{ "height": '40px', "background": "#222222", 'fontSize': "20px", "boxShadow": `none`, 'borderRadius': '0.8em', 'border': `3px solid ${offer1_color_primary}` }}
                                        ></input>
                                    </div>
                                    <div className="col-auto p-0 d-flex justify-content-center align-items-center" style={{ 'width': '20%' }}>
                                        <div className="d-flex justify-content-center align-items-center" style={{ 'backgroundColor': '#161616', 'width': '45px', "height": '28px', 'borderRadius': '8px' }}>
                                            {linked
                                                ? <MdLink onClick={() => setLinked(!linked)} size={"100%"} style={{ 'maxWidth': '26px' }} />
                                                : <MdLinkOff onClick={() => setLinked(!linked)} size={"100%"} style={{ 'maxWidth': '26px' }} />
                                            }
                                        </div>
                                    </div>
                                    <div className="col text-center ps-0">
                                        <input
                                            value={stake2}
                                            onChange={e => { setStake2(e.target.value); setLastStake(e.target.name) }}
                                            name='stake2'
                                            type="number"
                                            min="0"
                                            step={"10"}
                                            className="form-control text-center text-white placeholder-color-white p-0 text1"
                                            placeholder="€"
                                            style={{ "height": '40px', "background": "#222222", 'fontSize': "20px", "boxShadow": `none`, 'borderRadius': '0.8em', 'border': `3px solid ${offer2_color_primary}` }}
                                        ></input>                                    </div>
                                </div>
                                {/* payout */}
                                <div className="row">
                                    <div className="col text-center pe-0 text-secondary font1">
                                        {roundDigits(value1 * stake1 || 0)}€                                    </div>
                                    <div className="col-auto p-0 text-truncate text-uppercase text-secondary d-flex justify-content-center align-items-center font1" style={{ 'width': '20%', 'fontSize': '16px' }}>payout</div>
                                    <div className="col text-center ps-0 text-secondary font1">
                                        {roundDigits(value2 * stake2 || 0)}€
                                    </div>
                                </div>
                                {/* profit */}
                                <div className="row">
                                    <div className="col text-center pe-0 font1 fs-3" style={{ 'color': offer1_color_primary }}>
                                        {roundDigits(value1 * stake1 - stake1 - stake2 || 0)}€
                                    </div>
                                    <div className="col-auto p-0 text-truncate text-uppercase text-secondary d-flex justify-content-center align-items-center font1" style={{ 'width': '20%', 'fontSize': '16px' }}>profit</div>
                                    <div className="col text-center ps-0 font1 fs-3" style={{ 'color': offer2_color_primary }}>
                                        {roundDigits(value2 * stake2 - stake1 - stake2 || 0)}€
                                    </div>
                                </div>
                                {/* more */}
                                <div className={`row mt-4 ${showMore && 'd-none'}`}>
                                    <div
                                        className="btn-dark col-12 rounded-3 btn mt-2 text-secondary d-flex justify-content-center align-items-center"
                                        style={{ 'height': '40px', 'backgroundColor': '#161616' }}
                                        onClick={() => setShowMore(!showMore)}
                                    >
                                        <BiDotsHorizontalRounded size={"30px"} />
                                    </div>
                                </div>
                                {/* button */}
                                <div className={`row mt-4 ${!showMore && 'd-none'}`}>
                                    <Button onClick={() => setSelected('')} text={'Close'} icon={<MdClose size={'20px'} />} />
                                    <Button
                                        onClick={() => {
                                            (addedToBetTracker ? navigate('/bet-tracker') : createBets({
                                                setAddedToBetTracker, setError, bets: [
                                                    {
                                                        "account": account1,
                                                        "bettype": "arbitrage",
                                                        "game_type": arbitrage.offer1_game_type,
                                                        "site": arbitrage.offer1_site,
                                                        "name": arbitrage.offer1_name,
                                                        "competition": arbitrage.offer1_competition,
                                                        "market": arbitrage.market,
                                                        "bet": arbitrage.offer1_bet,
                                                        "value": value1,
                                                        "stake": stake1,
                                                        "start": arbitrage.offer1_start,
                                                        "sport": arbitrage.offer1_sport,
                                                        "group_key": arbitrage.group_key,
                                                        "status": "pending",
                                                        "sold_price": 0,
                                                    },
                                                    {
                                                        "account": account2,
                                                        "bettype": "arbitrage",
                                                        "game_type": arbitrage.offer2_game_type,
                                                        "site": arbitrage.offer2_site,
                                                        "name": arbitrage.offer2_name,
                                                        "competition": arbitrage.offer2_competition,
                                                        "market": arbitrage.market,
                                                        "bet": arbitrage.offer2_bet,
                                                        "value": value2,
                                                        "stake": stake2,
                                                        "start": arbitrage.offer2_start,
                                                        "sport": arbitrage.offer2_sport,
                                                        "group_key": arbitrage.group_key,
                                                        "status": "pending",
                                                        "sold_price": 0,
                                                    }
                                                ]
                                            }))
                                        }}
                                        text={`${addedToBetTracker ? 'Open BetTracker' : 'Add to BetTracker'}`}
                                        icon={<BiLineChart size={'20px'} />}
                                    />
                                    <Button onClick={() => setShowHide(!showHide)} text={'Hide for me'} icon={<BiHide size={'20px'} />} />
                                    <div className={`p-0 ps-5 ${!showHide && 'd-none'}`}>
                                        <Button onClick={() => { setShowHide(!showHide); hideGroup(group_key); setSelected('') }} text={'Only this arbitrage'} />
                                        <Button onClick={() => { setShowHide(!showHide); hideGame([offer1_gameid, offer2_gameid]); setSelected('') }} text={`Both games [${offer2_site},${offer1_site}]`} />
                                        <Button onClick={() => { setShowHide(!showHide); hideGame([offer1_gameid]); setSelected('') }} text={`This game [${offer1_site}]`} />
                                        <Button onClick={() => { setShowHide(!showHide); hideGame([offer2_gameid]); setSelected('') }} text={`This game [${offer2_site}]`} />
                                    </div>
                                    <Button onClick={() => !sure ? setSure(true) : (invalidGame([offer1_gameid, offer2_gameid]), setSure(false), setSelected(''))} text={!sure ? 'This is not Arbitrage' : "I'm sure"} icon={<RiEmotionSadLine size={'20px'} />} />
                                    <Button onClick={() => setShowAccounts(!showAccounts)} text={`${showAccounts ? "Don't show" : "Show"} Account/Available`} icon={<LuWallet size={'20px'} />} />
                                    <Button onClick={() => setShowInfo(!showInfo)} text={`${showInfo ? "Don't show" : "Show"} Info`} icon={<MdInfoOutline size={'20px'} />} />

                                    <div className={`text-secondary ${!showInfo && 'd-none'}`}>
                                        <div>
                                            created: {createdAgo < 100
                                                ? <>{createdAgo} seconds ago</>
                                                : <>{createdAgo < 6000
                                                    ? <>{(createdAgo / 60).toFixed(0)} minutes ago</>
                                                    : <>{(createdAgo / (60 * 60)).toFixed(0)} hours ago</>
                                                }</>
                                            }
                                        </div>
                                        <div>
                                            updated: {updatedAgo < 100
                                                ? <>{updatedAgo} seconds ago</>
                                                : <>{updatedAgo < 6000
                                                    ? <>{(updatedAgo / 60).toFixed(0)} minutes ago</>
                                                    : <>{(updatedAgo / (60 * 60)).toFixed(0)} hours ago</>
                                                }</>
                                            }
                                        </div>
                                        <div>
                                            checked: {arbitrage.checked} times
                                        </div>
                                        <div>
                                            home similarity: {(arbitrage.similarity_home * 100).toFixed(2)}%
                                        </div>
                                        <div>
                                            away similarity:  {(arbitrage.similarity_away * 100).toFixed(2)}%
                                        </div>
                                        <div>
                                            sport:  {arbitrage.offer1_sport}
                                        </div>
                                        <div>
                                            competition:  {arbitrage.offer1_competition}
                                        </div>
                                        <div>
                                            home paths:  | {[arbitrage.offer1_path1, arbitrage.offer1_path2, arbitrage.offer1_path3].filter(e => e).join(" | ")} |
                                        </div>
                                        <div>
                                            away paths: | {[arbitrage.offer2_path1, arbitrage.offer2_path2, arbitrage.offer2_path3].filter(e => e).join(" | ")} |
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            <Disclamer />
        </>
    )

}

const BigScreen = ({ parameters }) => {
    const { arbitrages, free, unpaidInvoices, userMessage, selected, setSelected, values } = parameters
    const { sure, setSure, setError, addedToBetTracker, setAddedToBetTracker, options, setOptions, showAccounts, setShowAccounts, accounts, setAccounts, account1, setAccount1, account2, setAccount2, changingValues, setChangingValues, lastStake, setLastStake, linked, setLinked, value2, setValue2, value1, setValue1, stake2, setStake2, stake1, setStake1 } = values
    const [showMore, setShowMore] = useState(false)
    const [showHide, setShowHide] = useState(false)
    const [showInfo, setShowInfo] = useState(false)
    const navigate = useNavigate()

    useEffect(() => { setShowMore(false); setShowInfo(false) }, [selected])

    const selected_arbitrage = arbitrages.find(e => e.group_key === selected)

    const createdAgo = (Math.abs(new Date().getTime() - new Date(selected_arbitrage?.createdAt).getTime()) / 1000).toFixed(0)
    const updatedAgo = (Math.abs(new Date().getTime() - new Date(selected_arbitrage?.updatedAt).getTime()) / 1000).toFixed(0)


    return (
        <>
            <div className='row '>
                <div
                    className='col-8  text-white overflow-auto'
                    style={{ "height": "calc(100vh - 56px)", "background": '#161616' }}
                >
                    {/* <Bonuses /> */}
                    <FreeVersion free={free} />
                    <UnpaidInvoices unpaidInvoices={unpaidInvoices} />
                    <UserMessage userMessage={userMessage} />
                    <NothingFound arbitrages={arbitrages} />
                    <Options options={options} setOptions={setOptions} />
                    {arbitrages.map(arbitrage => {
                        const { offer1_gameid, offer2_gameid, offer1_start, id, group_key, roi, offer1_url, offer2_url, offer1_site, offer2_site, offer1_home, offer2_away, market, market_quantity, offer1_bet, offer2_bet, offer1_color_primary, offer2_color_primary } = arbitrage
                        const start = formatIsoString(offer1_start)

                        const createdAgo = (Math.abs(new Date().getTime() - new Date(arbitrage.createdAt).getTime()) / 1000).toFixed(0)
                        const updatedAgo = (Math.abs(new Date().getTime() - new Date(arbitrage.updatedAt).getTime()) / 1000).toFixed(0)




                        return (
                            <div
                                style={{ 'border': `${group_key === selected ? '' : 'solid 3px rgba(255, 0, 0, 0) '}`, "background": "#222222", 'minHeight': '100px' }}
                                className={`font2 row mx-1 mb-3  rounded rounded-4 align-middle ${group_key == selected ? 'border border-3 border-white' : ''}`}
                                onClick={() => (group_key != selected && setSelected(group_key))}
                                key={arbitrage.id}
                            >
                                <div className=' d-flex justify-content-center align-items-center' style={{ 'width': window.innerWidth <= 1250 ? '80px' : '150px', 'fontSize': window.innerWidth <= 1250 ? '1.2rem' : '1.65rem' }}>
                                    {arbitrage.roi.toFixed(2)}%
                                </div>
                                <div className='col p-0'>
                                    <div className='h-100 d-flex flex-column justify-content-center'>
                                        <div className='container-fluid'>
                                            <div className='row'>
                                                <div className='col-auto d-flex align-items-end justify-content-center p-0 w-100' style={{ 'maxWidth': '70px', 'fontSize': window.innerWidth <= 1250 ? '0.8rem' : '1rem' }}>
                                                    <span>{start.dateText}</span>
                                                </div>
                                                <div className='col d-flex align-items-end' style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                    <span className='lh-base d-inline-block text-truncate' style={{ width: '100%', 'fontSize': window.innerWidth <= 1050 ? '0.8rem' : '1rem' }}>
                                                        {arbitrage.offer1_home}
                                                        <span className="text-secondary" style={{ fontSize: '12px' }}> vs. </span>
                                                        {arbitrage.offer2_away}
                                                    </span>
                                                </div>
                                                <div className='col-auto p-0 d-flex align-items-end text-secondary text-uppercase' style={{ 'fontSize': window.innerWidth <= 1250 ? '0.8rem' : '1rem' }}>
                                                    {arbitrage.offer1_bet}
                                                </div>
                                                <div className='col-auto py-0 px-1 mx-2 d-flex align-items-end' style={{ width: window.innerWidth <= 1250 ? '70px' : '80px' }}>
                                                    <Link to={offer1_url} target="_blank" className="w-100 h-100 d-flex justify-content-center align-items-end">
                                                        <img
                                                            src={require(`../../static/images/sites/${offer1_site}.png`)}
                                                            style={{ maxWidth: '100%', height: 'auto', marginBottom: '2px' }}
                                                            className="rounded-1"
                                                            alt="Offer 1"
                                                        />
                                                    </Link>
                                                </div>
                                                <div className='col-auto p-0 d-flex align-items-end' style={{ 'width': '50px', 'color': arbitrage.offer1_color_primary, 'fontSize': window.innerWidth <= 1250 ? '0.8rem' : '1rem' }}>
                                                    <span>{arbitrage.offer1_value}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='container-fluid'>
                                            <div className='row'>
                                                <div className='col-auto d-flex align-items-start justify-content-center p-0 w-100' style={{ 'maxWidth': '70px', 'fontSize': window.innerWidth <= 1250 ? '0.8rem' : '1rem' }}>
                                                    <span>{start.hour}:{start.minute}</span>
                                                </div>

                                                <div className='col d-flex align-items-start' style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                    <span className='lh-base d-inline-block text-truncate text-secondary text-uppercase' style={{ width: '100%', 'fontSize': window.innerWidth <= 1050 ? '0.8rem' : '1rem' }}>
                                                        {arbitrage.market}</span>
                                                </div>
                                                <div className='col-auto p-0 d-flex align-items-start text-secondary text-uppercase' style={{ 'fontSize': window.innerWidth <= 1250 ? '0.8rem' : '1rem' }}>
                                                    {arbitrage.offer2_bet}
                                                </div>
                                                <div className='col-auto py-0 px-1 mx-2 d-flex align-items-start' style={{ width: window.innerWidth <= 1250 ? '70px' : '80px' }}>
                                                    <Link to={offer2_url} target="_blank" className="w-100 h-100 d-flex justify-content-center align-items-start">
                                                        <img
                                                            src={require(`../../static/images/sites/${offer2_site}.png`)}
                                                            style={{ maxWidth: '100%', height: 'auto', marginTop: '2px' }}
                                                            className="rounded-1"
                                                            alt="Offer 1"
                                                        />
                                                    </Link>
                                                </div>
                                                <div className='col-auto p-0 d-flex align-items-start' style={{ 'width': '50px', 'color': arbitrage.offer2_color_primary, 'fontSize': window.innerWidth <= 1250 ? '0.8rem' : '1rem' }}>
                                                    <span>{arbitrage.offer2_value}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center align-items-center' style={{ 'width': window.innerWidth <= 1450 ? '50px' : '100px' }}>
                                    <div>
                                        {['football', 'tennis', 'hockey', 'basketball', 'handball', 'volleyball'].includes(arbitrage.offer1_sport)
                                            ? <img src={require(`../../static/images/sports/${arbitrage.offer1_sport}.png`)} className='w-100' style={{ 'maxWidth': '36px' }}></img>
                                            : <div>{ }</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <Disclamer />
                </div>
                <div className='col-4 p-0 pe-3'>
                    {selected_arbitrage &&
                        <div
                            className="rounded rounded-4 container-fluid py-2 overflow-auto pb-2"
                            style={{ 'height': "calc(100vh - 72px)", "background": "#222222", 'padding': window.innerWidth <= 1450 ? '1rem' : '2rem' }}
                        >
                            <div className='row mx-0 mb-5'><div className='text-light text-center fs-3'>Details</div></div>

                            {/* site */}
                            <div className='row mx-0 mt-4'>
                                <div className='col d-flex justify-content-center'>
                                    <Link to={selected_arbitrage.offer1_url} target="_blank" className="d-flex align-items-center">
                                        <img src={require(`../../static/images/sites/${selected_arbitrage.offer1_site}.png`)} style={{ 'maxWidth': window.innerWidth <= 1150 ? '80px' : '100px' }} className="rounded w-100" alt="Offer 1"></img>
                                    </Link>
                                </div>
                                <div className='col-2'></div>
                                <div className='col d-flex justify-content-center'>
                                    <Link to={selected_arbitrage.offer2_url} target="_blank" className="d-flex align-items-center">
                                        <img src={require(`../../static/images/sites/${selected_arbitrage.offer2_site}.png`)} style={{ 'maxWidth': window.innerWidth <= 1150 ? '80px' : '100px' }} className="rounded w-100" alt="Offer 1"></img>
                                    </Link>
                                </div>
                            </div>
                            {/* name */}
                            <div className='row mx-0 mt-4'>
                                <div className='col-5 d-flex justify-content-center flex-column text-center' style={{ fontSize: window.innerWidth <= 1150 ? '0.85rem' : '1rem' }}>
                                    <div className='lh-1 my-1'>{selected_arbitrage.offer1_home}</div>
                                    <div className='lh-1 my-1'>{selected_arbitrage.offer1_away}</div>
                                    <div className='text-secondary text-uppercase lh-1 my-1'>{selected_arbitrage.offer1_competition}</div>
                                </div>
                                <div className='col-2'></div>
                                <div className='col-5 d-flex justify-content-center flex-column text-center' style={{ fontSize: window.innerWidth <= 1150 ? '0.85rem' : '1rem' }}>
                                    <div className='lh-1 my-1'>{selected_arbitrage.offer2_home}</div>
                                    <div className='lh-1 my-1'>{selected_arbitrage.offer2_away}</div>
                                    <div className='text-secondary text-uppercase lh-1 my-1'>{selected_arbitrage.offer2_competition}</div>
                                </div>
                            </div>
                            {/* paths */}
                            <div className='row mx-0 mt-4'>
                                <div className='col-5 d-flex justify-content-center flex-column text-center' style={{ fontSize: window.innerWidth <= 1150 ? '0.85rem' : '1rem' }}>
                                    <div className='lh-1 my-1'>{selected_arbitrage.offer1_path1}</div>
                                    <div className='lh-1 my-1'>{selected_arbitrage.offer1_path2 ? selected_arbitrage.offer1_path2 : selected_arbitrage.offer1_path3}</div>
                                    <div className='lh-1 my-1'>{selected_arbitrage.offer1_path2 ? selected_arbitrage.offer1_path3 : ""}</div>
                                </div>
                                <div className='col-2'></div>
                                <div className='col-5 d-flex justify-content-center flex-column text-center' style={{ fontSize: window.innerWidth <= 1150 ? '0.85rem' : '1rem' }}>
                                    <div className='lh-1 my-1'>{selected_arbitrage.offer2_path1}</div>
                                    <div className='lh-1 my-1'>{selected_arbitrage.offer2_path2 ? selected_arbitrage.offer2_path2 : selected_arbitrage.offer2_path3}</div>
                                    <div className='lh-1 my-1'>{selected_arbitrage.offer2_path2 ? selected_arbitrage.offer2_path3 : ""}</div>
                                </div>
                            </div>
                            {/* value */}
                            <div className='row mx-0 mt-4'>
                                <div className='col d-flex justify-content-center flex-column text-center'>
                                    {changingValues
                                        ?
                                        <input
                                            value={value1}
                                            onChange={e => { setValue1(e.target.value) }}
                                            name='value1'
                                            type="number"
                                            min="1"
                                            step={"0.1"}
                                            className="form-control text-center placeholder-color-white p-0 text1 rounded rounded-3"
                                            placeholder="€"
                                            style={{ "height": '38px', "background": "#222222", 'fontSize': "20px", "boxShadow": `none`, 'border': `3px solid ${selected_arbitrage.offer1_color_primary}`, "color": selected_arbitrage.offer1_color_primary }}
                                        ></input>
                                        : <span className='fs-3' style={{ 'color': selected_arbitrage.offer1_color_primary }}>{value1}</span>
                                    }
                                </div>
                                <div className='col-2 d-flex justify-content-center text-secondary'>
                                    {changingValues
                                        ? <BiRefresh onClick={() => setChangingValues(!changingValues)} size={"100%"} style={{ 'maxWidth': '22px' }} />
                                        : <MdOutlineEdit onClick={() => setChangingValues(!changingValues)} size={"100%"} style={{ 'maxWidth': '20px' }} />
                                    }
                                </div>

                                <div className='col d-flex justify-content-center flex-column text-center'>
                                    {changingValues
                                        ?
                                        <input
                                            value={value2}
                                            onChange={e => { setValue2(e.target.value) }}
                                            name='value2'
                                            type="number"
                                            min="1"
                                            step={"0.1"}
                                            className="form-control text-center placeholder-color-white p-0 text1"
                                            placeholder="€"
                                            style={{ "height": '38px', "background": "#222222", 'fontSize': "20px", "boxShadow": `none`, 'borderRadius': '0.8em', 'border': `3px solid ${selected_arbitrage.offer2_color_primary}`, "color": selected_arbitrage.offer2_color_primary }}
                                        ></input>
                                        : <span className='fs-3' style={{ 'color': selected_arbitrage.offer2_color_primary }}>{value2}</span>
                                    }
                                </div>
                            </div>
                            {/* stake */}
                            <div className='row mx-0 my-4'>
                                <div className='col'>
                                    <input
                                        value={stake1}
                                        onChange={e => { setStake1(e.target.value); setLastStake(e.target.name) }}
                                        name='stake1'
                                        type="number"
                                        min="0"
                                        step={"10"}
                                        className="form-control text-center placeholder-color-white p-0 text1 rounded rounded-3"
                                        placeholder="€"
                                        style={{ "height": '40px', "background": "#222222", 'fontSize': "20px", "boxShadow": `none`, 'border': `3px solid ${selected_arbitrage.offer1_color_primary}`, 'color': `${selected_arbitrage.offer1_color_primary}` }}
                                    ></input>
                                </div>
                                <div className='col-2 d-flex justify-content-center text-secondary'>
                                    {linked
                                        ? <MdLink onClick={() => setLinked(!linked)} size={"100%"} style={{ 'maxWidth': '26px' }} />
                                        : <MdLinkOff onClick={() => setLinked(!linked)} size={"100%"} style={{ 'maxWidth': '26px' }} />
                                    }
                                </div>
                                <div className='col'>
                                    <input
                                        value={stake2}
                                        onChange={e => { setStake2(e.target.value); setLastStake(e.target.name) }}
                                        name='stake2'
                                        type="number"
                                        min="0"
                                        step={"10"}
                                        className="form-control text-center placeholder-color-white p-0 text1 rounded rounded-3"
                                        placeholder="€"
                                        style={{ "height": '40px', "background": "#222222", 'fontSize': "20px", "boxShadow": `none`, 'border': `3px solid ${selected_arbitrage.offer2_color_primary}`, 'color': `${selected_arbitrage.offer2_color_primary}` }}
                                    ></input>
                                </div>
                            </div>
                            {/* account */}
                            <div className={`row mx-0 mt-1 ${!showAccounts && 'd-none'}`}>
                                <div className='col'>
                                    <input
                                        value={account1}
                                        onChange={e => { setAccount1(e.target.value) }}
                                        name='account1'
                                        type="text"
                                        className="form-control text-center placeholder-color-white p-0 text1 text-light text-truncate rounded rounded-3 "
                                        placeholder="Enter account name"
                                        list='datalist_account1'
                                        style={{ 'fontSize': window.innerWidth <= 1450 ? '1rem' : '1rem',  'background': '#222222', 'boxShadow': 'none', 'border': `none` }}
                                    />
                                    <datalist id='datalist_account1'>
                                        {accounts.filter(e => e.site === selected_arbitrage.offer1_site).map(e => {
                                            return (
                                                <option onClick={() => setAccount1(e.account)} id={e.account} value={`${e.account}`} label={`${e.balance.toFixed(2)}€`} />
                                            )
                                        })}
                                    </datalist>
                                </div>
                                <div className='col-2 d-flex justify-content-center font1 text-secondary text-uppercase align-items-center'>
                                    account
                                </div>
                                <div className='col '>
                                    <input
                                        value={account2}
                                        onChange={e => { setAccount2(e.target.value) }}
                                        name='account2'
                                        type="text"
                                        className="form-control text-center placeholder-color-white p-0 text1 text-light text-truncate rounded rounded-3 "
                                        placeholder="Enter account name"
                                        list='datalist_account2'
                                        style={{ 'fontSize': window.innerWidth <= 1450 ? '1rem' : '1rem',  'background': '#222222', 'boxShadow': 'none', 'border': `none` }}
                                    />
                                    <datalist id='datalist_account2'>
                                        {accounts.filter(e => e.site === selected_arbitrage.offer2_site).map(e => {
                                            return (
                                                <option onClick={() => setAccount2(e.account)} id={e.account} value={`${e.account}`} label={`${e.balance.toFixed(2)}€`} />
                                            )
                                        })}
                                    </datalist>
                                </div>
                            </div>
                            {/* available */}
                            <div className={`row mx-0 mt-1 ${!showAccounts && 'd-none'}`}>
                                <div className='col d-flex justify-content-center'>
                                    {roundDigits(accounts.find(e => e.site === selected_arbitrage.offer1_site && e.account === account1)?.balance || 0)}
                                </div>
                                <div className='col-2 d-flex justify-content-center font1 text-secondary text-uppercase'>
                                    available
                                </div>
                                <div className='col d-flex justify-content-center'>
                                    {roundDigits(accounts.find(e => e.site === selected_arbitrage.offer2_site && e.account === account2)?.balance || 0)}
                                </div>
                            </div>
                            {/* payout */}
                            <div className='row mx-0 mt-1'>
                                <div className='col d-flex justify-content-center'>
                                    {roundDigits(value1 * stake1 || 0)}
                                </div>
                                <div className='col-2 d-flex justify-content-center font1 text-secondary text-uppercase'>
                                    payout
                                </div>
                                <div className='col d-flex justify-content-center'>
                                    {roundDigits(value2 * stake2 || 0)}
                                </div>
                            </div>
                            {/* profit */}
                            <div className='row mx-0 mt-1'>
                                <div className='col d-flex justify-content-center fs-5' style={{ color: selected_arbitrage.offer1_color_primary }}>
                                    {roundDigits(value1 * stake1 - stake1 - stake2 || 0)}
                                </div>
                                <div className='col-2 d-flex justify-content-center align-items-center font1 text-secondary text-uppercase'>
                                    profit
                                </div>
                                <div className='col d-flex justify-content-center fs-5' style={{ color: selected_arbitrage.offer2_color_primary }}>
                                    {roundDigits(value2 * stake2 - stake1 - stake2 || 0)}
                                </div>
                            </div>
                            {/* buttons */}
                            <div className='row mx-0'>
                                <Button
                                    onClick={() => {
                                        (addedToBetTracker ? navigate('/bet-tracker') : createBets({
                                            setAddedToBetTracker, setError, bets: [
                                                {
                                                    "account": account1,
                                                    "bettype": "arbitrage",
                                                    "game_type": selected_arbitrage.offer1_game_type,
                                                    "site": selected_arbitrage.offer1_site,
                                                    "name": selected_arbitrage.offer1_name,
                                                    "competition": selected_arbitrage.offer1_competition,
                                                    "market": selected_arbitrage.market,
                                                    "bet": selected_arbitrage.offer1_bet,
                                                    "value": value1,
                                                    "stake": stake1,
                                                    "start": selected_arbitrage.offer1_start,
                                                    "sport": selected_arbitrage.offer1_sport,
                                                    "group_key": selected_arbitrage.group_key,
                                                    "status": "pending",
                                                    "sold_price": 0,
                                                },
                                                {
                                                    "account": account2,
                                                    "bettype": "arbitrage",
                                                    "game_type": selected_arbitrage.offer2_game_type,
                                                    "site": selected_arbitrage.offer2_site,
                                                    "name": selected_arbitrage.offer2_name,
                                                    "competition": selected_arbitrage.offer2_competition,
                                                    "market": selected_arbitrage.market,
                                                    "bet": selected_arbitrage.offer2_bet,
                                                    "value": value2,
                                                    "stake": stake2,
                                                    "start": selected_arbitrage.offer2_start,
                                                    "sport": selected_arbitrage.offer2_sport,
                                                    "group_key": selected_arbitrage.group_key,
                                                    "status": "pending",
                                                    "sold_price": 0,
                                                }
                                            ]
                                        }))
                                    }}
                                    text={`${addedToBetTracker ? 'Open BetTracker' : 'Add to BetTracker'}`}
                                    icon={<BiLineChart size={'20px'} />}
                                />
                                <Button onClick={() => setShowHide(!showHide)} text={'Hide for me'} icon={<BiHide size={'20px'} />} />
                                <div className={`p-0 ps-5 ${!showHide && 'd-none'}`}>
                                    <Button onClick={() => { setShowHide(!showHide); hideGroup(selected_arbitrage.group_key); setSelected('') }} text={'Only this arbitrage'} />
                                    <Button onClick={() => { setShowHide(!showHide); hideGame([selected_arbitrage.offer1_gameid, selected_arbitrage.offer2_gameid]); setSelected('') }} text={`Both games [${selected_arbitrage.offer2_site},${selected_arbitrage.offer1_site}]`} />
                                    <Button onClick={() => { setShowHide(!showHide); hideGame([selected_arbitrage.offer1_gameid]); setSelected('') }} text={`This game [${selected_arbitrage.offer1_site}]`} />
                                    <Button onClick={() => { setShowHide(!showHide); hideGame([selected_arbitrage.offer2_gameid]); setSelected('') }} text={`This game [${selected_arbitrage.offer2_site}]`} />
                                </div>
                                <Button onClick={() => !sure ? setSure(true) : (invalidGame([selected_arbitrage.offer1_gameid, selected_arbitrage.offer2_gameid]), setSure(false), setSelected(''))} text={!sure ? 'This is not Arbitrage' : "I'm sure"} icon={<RiEmotionSadLine size={'20px'} />} />
                                <Button onClick={() => setShowAccounts(!showAccounts)} text={`${showAccounts ? "Don't show" : "Show"} Account/Available`} icon={<LuWallet size={'20px'} />} />
                                <Button onClick={() => setShowInfo(!showInfo)} text={`${showInfo ? "Don't show" : "Show"} Info`} icon={<MdInfoOutline size={'20px'} />} />

                                <div className={`text-secondary ${!showInfo && 'd-none'}`}>
                                    <div>
                                        created: {createdAgo < 100
                                            ? <>{createdAgo} seconds ago</>
                                            : <>{createdAgo < 6000
                                                ? <>{(createdAgo / 60).toFixed(0)} minutes ago</>
                                                : <>{(createdAgo / (60 * 60)).toFixed(0)} hours ago</>
                                            }</>
                                        }
                                    </div>
                                    <div>
                                        updated: {updatedAgo < 100
                                            ? <>{updatedAgo} seconds ago</>
                                            : <>{updatedAgo < 6000
                                                ? <>{(updatedAgo / 60).toFixed(0)} minutes ago</>
                                                : <>{(updatedAgo / (60 * 60)).toFixed(0)} hours ago</>
                                            }</>
                                        }
                                    </div>
                                    <div>
                                        checked: {selected_arbitrage.checked} times
                                    </div>
                                    <div>
                                        home similarity: {(selected_arbitrage.similarity_home * 100).toFixed(2)}%
                                    </div>
                                    <div>
                                        away similarity:  {(selected_arbitrage.similarity_away * 100).toFixed(2)}%
                                    </div>
                                    <div>
                                        sport:  {selected_arbitrage.offer1_sport}
                                    </div>
                                    <div>
                                        competition:  {selected_arbitrage.offer1_competition}
                                    </div>
                                    <div>
                                        home paths:  | {[selected_arbitrage.offer1_path1, selected_arbitrage.offer1_path2, selected_arbitrage.offer1_path3].filter(e => e).join(" | ")} |
                                    </div>
                                    <div>
                                        away paths: | {[selected_arbitrage.offer2_path1, selected_arbitrage.offer2_path2, selected_arbitrage.offer2_path3].filter(e => e).join(" | ")} |
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div >
        </>
    )
}

const PrematchArbitrage = () => {
    const token = getCookie('token')
    const newDataIn = 3000
    const [arbitrages, setArbitrages] = useState([])
    const [free, setFree] = useState(false)
    const [unpaidInvoices, setUnpaidInvoices] = useState([])
    const [userMessage, setUserMessage] = useState('')
    const [dataFetched, setDataFetched] = useState(false)
    const [error, setError] = useState('')
    const [selected, setSelected] = useState('')
    const [accounts, setAccounts] = useState([])

    const [account1, setAccount1] = useState('')
    const [account2, setAccount2] = useState('')

    const [stake1, setStake1] = useState('')
    const [stake2, setStake2] = useState('')
    const [value1, setValue1] = useState('')
    const [value2, setValue2] = useState('')
    const [linked, setLinked] = useState(true)
    const [lastStake, setLastStake] = useState('')
    const [changingValues, setChangingValues] = useState(false)
    const [showAccounts, setShowAccounts] = useState((getCookie("showAccount") === "false") ? false : true)
    const [options, setOptions] = useState(getCookie('options') ? JSON.parse(getCookie('options')) : defaultOptions)
    const [addedToBetTracker, setAddedToBetTracker] = useState(false)
    const [sure, setSure] = useState(false)

    const values = { sure, setSure, setError, addedToBetTracker, setAddedToBetTracker, options, setOptions, showAccounts, setShowAccounts, accounts, setAccounts, account1, setAccount1, account2, setAccount2, changingValues, setChangingValues, lastStake, setLastStake, linked, setLinked, value2, setValue2, value1, setValue1, stake2, setStake2, stake1, setStake1 }


    useEffect(() => {
        if (arbitrages.length > 0) {
            if (!arbitrages.some(e => e.group_key === selected)) {
                if (window.innerWidth > 950) {
                    setSelected(arbitrages[0].group_key)
                }
            }
        }
    }, [arbitrages])

    const fetchNewData = async () => {
        axios.post(`${process.env.REACT_APP_API}/abitrage/prematch`, { token, options: JSON.parse(getCookie('options')) })
            .then(response => {
                setArbitrages(response.data.arbitrages)
                setUnpaidInvoices(response.data.invoices.filter(e => e.payment_state === "not_paid").length)
                setDataFetched(true)
                setFree(response.data.free)
                setUserMessage(response.data.user_message)
                setAccounts(response.data.accounts)

            })
            .catch(error => {
                // setError(error?.response?.data?.error || '')
                // setArbitrages([])
                // setUnpaidInvoices([])
                // setDataFetched(true)
                // setFree(null)
                // setUserMessage('')
                // setAccounts([])
            })
    }

    useEffect(() => {
        setCookie("options", (JSON.stringify(options)))
        fetchNewData()
    }, [options])

    useEffect(() => {
        const arbitrage = arbitrages.find(e => e.group_key === selected)
        if (arbitrage) {
            setCookie(`lastAccount_${arbitrage.offer1_site}`, account1)
            setCookie(`lastAccount_${arbitrage.offer2_site}`, account2)
        }
    }, [account1, account2])

    useEffect(() => {
        setError('')
        fetchNewData()
        const interval = setInterval(fetchNewData, newDataIn)
        return () => clearInterval(interval)
    }, [token])

    useEffect(() => {
        if (error) {
            console.log('errorerror', error)
            toast.error(error)
        }
    }, [error])

    useEffect(() => {
        const arbitrage = arbitrages.find(e => e.group_key === selected)
        if (arbitrage) {
            fetchNewData()
            setAccount1(getCookie(`lastAccount_${arbitrage.offer1_site}`) || "")
            setAccount2(getCookie(`lastAccount_${arbitrage.offer2_site}`) || "")
            setSure(false)
            setAddedToBetTracker(false)
        }
    }, [selected])

    useEffect(() => {
        if (!changingValues && selected) {
            const selectedArbitrage = arbitrages.find(e => e.group_key === selected)
            if (selectedArbitrage) {
                setValue1(selectedArbitrage.offer1_value)
                setValue2(selectedArbitrage.offer2_value)
            }
        }
    }, [changingValues, selected, arbitrages])

    useEffect(() => {
        setStake1('')
        setStake2('')
        setLastStake('')
    }, [value1, value2])

    useEffect(() => {
        const arbitrage = arbitrages.find(e => e.group_key === selected)
        if (linked && arbitrage && lastStake === 'stake1') {
            const stake2 = (stake1 * value1 / value2)
            setStake2(roundDigits(stake2))
            if (stake1 === '') {
                setStake2('')
            }
        }
    }, [stake1])

    useEffect(() => {
        const arbitrage = arbitrages.find(e => e.group_key === selected)
        if (linked && arbitrage && lastStake === 'stake2') {
            const stake1 = (stake2 * value2 / value1)
            setStake1(roundDigits(stake1))
            if (stake2 === '') {
                setStake1('')
            }
        }
    }, [stake2])

    useEffect(() => {
        if (showAccounts === 'false') {
            setCookie('showAccount', false)
        } else {
            setCookie('showAccount', true)
        }
    }, [showAccounts])

    if (!isAuth()) { return <Layout><div className='text-light'>Prihlaste sa</div></Layout> }

    if (dataFetched) {
        const parameters = { arbitrages, free, unpaidInvoices, selected, setSelected, userMessage, values }
        return (
            <Layout>
                <ToastContainer />
                <div className="overflow-auto container-fluid text-white font2 " style={{ "minHeight": "calc(100vh - 56px)", "background": "#161616" }} >
                    {window.innerWidth <= 950
                        ? <SmallScreen parameters={parameters} />
                        : <BigScreen parameters={parameters} />
                    }
                </div>

            </Layout>
        )
    } else {
        return (
            <Layout>
                <div className="container d-flex align-items-center justify-content-center" style={{ "height": "calc(100vh - 56px)" }}>
                    <ClipLoader color="#000" size={30} margin={5} speedMultiplier={1} />
                </div>
            </Layout>
        )
    }
}

export default PrematchArbitrage